/**
 * SCROLL: Stickybar
 * jQuery version
 * by SalsaLab
 */

(function($) {

	$.fn.slb_sticky = function(ops)
	{
		// get dedaults
		var _ops = $.extend({}, $.fn.slb_sticky.defaults, ops);
		var container = this;
		var breakpoint = null;
		var breakpoints = [];


		// get Breakpoints
		breakpoints = Object.keys(_ops.breakpoints);


		// get breakpoint value
		for (var i = 0; i < breakpoints.length; i++)
		{
			if(typeof breakpoints[i] === 'string')
			{
				var idx = salsalab.breakpoints[breakpoints[i]];
				_ops.breakpoints[idx] = _ops.breakpoints[breakpoints[i]];
				delete _ops.breakpoints[breakpoints[i]];
				breakpoints[i] = salsalab.breakpoints[breakpoints[i]];
			}
		}


		// set the current breakpoint
		var setBreakpoint = function()
		{
			var _w = $(window).width();
			for (var i = 0; i < breakpoints.length; i++)
			{
				if(_w >= breakpoints[i] && breakpoint < breakpoints[i])
				{
					breakpoint = breakpoints[i];
				}
			}

			if(_ops.breakpoints[breakpoint].top_limit !== undefined)
			{
				top_limit = _ops.breakpoints[breakpoint].top_limit
			}

			else
			{
				top_limit = 0;
			}

			// run the stickyness
			setSticky();
		};

		// execute the scroll
		var setSticky = function()
		{
			if($(document).scrollTop() > top_limit)
			{
				$(container).addClass(_ops.stickyclass);
			}

			else
			{
				$(container).removeClass(_ops.stickyclass);
			}
		};


		// add listener for the scroll
		$(window).on('scroll', function(e)
		{
			setSticky();
		});

		// add listener for window resize
		$(window).on('resize', function(e)
		{
			setBreakpoint();
		});
		$(window).on('orientationchange', function(e)
		{
			setBreakpoint();
		});

		// run at the init
		setBreakpoint();

		return this.each(function() {});
	};


	// defaults
	$.fn.slb_sticky.defaults = {
		breakpoints: {
			xs : {
				top_limit : 50,
			}
		},
		stickyclass: 'sticky',
	};

}(jQuery));
