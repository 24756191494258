jQuery(function() {

	// mobile menu
	$('#main-menu').slb_mobile_drop_menu('md');

	// function formGracias(){
	// 	if(window.location.hash == '#contact-form') {
	// 		$('.sec-contacto .buttons').after('<p>Gracias por contactarnos</p>');
	// 	}
	// }

	function scrollPage() {

		jQuery('#mnu-contacto a').on('click',
			function(e) {
				e.preventDefault();

				var target = this.hash;
				var $target = $(target);

				jQuery('html, body').stop().animate({
					'scrollTop' : $target.offset().top
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			});
	}


	function carruselTestimonial() {
		if($('#andari-slider-h').length > 0)
		{
			var homeswiper = new Swiper('#andari-slider-h', {
				direction           : 'horizontal',
				slidesPerView				: 1,
				keyboardControl     : true,
				mousewheelControl   : true,
				pagination          : '#andari-slider-h  .swiper-pagination',
				paginationClickable : true
			});
		}
	}

	function contactOrder() {

		if($('.sec-contacto .contact-form').length > 0) {

			jQuery('.sec-contacto .contact-form').before('<div class="contact-top"></div>');
			jQuery('.sec-contacto .contact-top').append($(".title-contact"));
			jQuery('.sec-contacto .contact-top').append($(".subtitle-contact"));
			jQuery('.sec-contacto .contact-top').append($(".text-contact"));

			jQuery('.sec-contacto .contact-top').append($(".text-contact-title-ubicacion"));
			jQuery('.sec-contacto .contact-top').append($(".text-contact-text-ubicacion"));			
		}
	}

	function formMessage() { 
		var form = $('#contact-form');
		form.submit(function(e) {
			// prevent form submission

					$('#contacto').addClass("onload");

			e.preventDefault();
			$.ajax({
				url: form.attr('action'),
				type: form.attr('method'),
				dataType: 'html',
				data: form.serialize(),
				success: function(result) {
					$('#contacto .content-form ').append('<p id="msg-contact" class="msg-succes">Gracias por contactarnos</p>');
									$('#contacto').removeClass("onload");
				}
			});
			$(form)[0].reset();
		});
	}

	carruselTestimonial();
	scrollPage();
	contactOrder();
	formMessage();

	$('#loadgal').click(function(){
		var elements = $(".galley-row div"); //get thee element inside the gallery
		var themath = Math.round((elements.length)/3); //get the number of rows
		var theheight = themath * 286; //get the toal height of the gallery
		$('.galley-row').css('height', theheight);
		$('.galley-row').addClass('expanded');
		$(this).css('display', 'none');
	})

});

//WAYPOINTS
/*jQuery(document).ready(function() {

	if ($.fn.waypoint) {

		function showPoints() {

			jQuery('.sec-conexiones').waypoint( {
				handler: function() {
					$(this).find('#andari-lupa').addClass('on-item');
					$(this).find('#andari-personas').addClass('on-item');
					$(this).find('#andari-escaleras').addClass('on-item');
				},
				offset: '66%'
			});


			jQuery('.subtitle-line').waypoint( {
				handler: function() {
					$(this).addClass('flash-ba');
				},
				offset: '66%'
			});

			jQuery('#contacto').waypoint( {
				handler: function() {
					$(this).addClass('on-item');
				},
				offset: '66%'
			});


			jQuery('#home-servicios').waypoint( {
				handler: function() {
					$(this).find('.col-lg-6').addClass('on-item');
				},
				offset: '66%'
			});


			jQuery('#somos-valores').waypoint( {
				handler: function() {
					$(this).find('li').addClass('on-item');
				},
				offset: '66%'
			});



			jQuery('.bg-services-onda').waypoint( {
				handler: function() {
					$(this).find('li').addClass('on-item');
				},
				offset: '66%'
			});

		}

	}

		showPoints();
});*/


//SEARCH
jQuery(document).ready(function() {
	$("#mnu-Search").click(function(){
		$('.search-wrapper').toggleClass('show-search');
	  });
});